import React from "react";
import Page from "./../../components/Page";
import Section from "./../../components/Section";
import SEO from "./../../components/SEO";

import Title, { SubTitle } from "../../components/releaseNotes/Title";
import Header from "../../components/releaseNotes/Header";
import Feature from "../../components/releaseNotes/Feature";
import VideoView from "../../components/releaseNotes/VideoView";

import workflowSteps from "../../images/3p8-workflow-step.png";
import autocomplete from "../../images/3p8-autocomplete.png";
import ultrasound from "../../images/3p8-ultrasound.png";
import dicom from "../../images/3p8-dicom.png";
import toolbar from "../../images/3p8-toolbar.jpg";
import MarkdownRenderer from "../../components/MarkdownRenderer";

const ChangeLog = `
* docs: updates images ([PR#4064](https://github.com/OHIF/Viewers/pull/4064))
* fix(toolbox): Preserve user-specified tool state and streamline command execution ([PR#4063](https://github.com/OHIF/Viewers/pull/4063))
* fix(viewport-webworker-segmentation): Resolve issues with viewport detection, webworker termination, and segmentation panel layout change ([PR#4059](https://github.com/OHIF/Viewers/pull/4059))
* fix(hp): Fails to display any layouts in the layout selector if first layout has multiple stages ([PR#4058](https://github.com/OHIF/Viewers/pull/4058))
* fix(vewport): Add missing blendmodes from cornerstonejs ([PR#4055](https://github.com/OHIF/Viewers/pull/4055))
* feat(tmtv-mode): Add Brush tools and move SUV peak calculation to web worker ([PR#4053](https://github.com/OHIF/Viewers/pull/4053))
* fix(layouts): and fix thumbnail in touch and update migration guide for 3.8 release ([PR#4052](https://github.com/OHIF/Viewers/pull/4052))
* fix(bugs): and replace seriesInstanceUID and seriesInstanceUIDs URL with seriesInstanceUIDs ([PR#4049](https://github.com/OHIF/Viewers/pull/4049))
* fix(bugs): enhancements and bug fixes - final  ([PR#4048](https://github.com/OHIF/Viewers/pull/4048))
* docs(Documentation): Fix wrong link for the components library in the architecture.md ([PR#4045](https://github.com/OHIF/Viewers/pull/4045))
* fix(bugs): enhancements and bug fixes - more ([PR#4043](https://github.com/OHIF/Viewers/pull/4043))
* fix(viewport): Reset viewport state and fix CINE looping, thumbnail resolution, and dynamic tool settings ([PR#4037](https://github.com/OHIF/Viewers/pull/4037))
* fix(bugs): enhancements and bug fixes ([PR#4036](https://github.com/OHIF/Viewers/pull/4036))
* feat(SM): remove SM measurements from measurement panel ([PR#4022](https://github.com/OHIF/Viewers/pull/4022))
* fix(general): enhancements and bug fixes ([PR#4018](https://github.com/OHIF/Viewers/pull/4018))
* fix(dicom-video): Update get direct func for dicom json to use url if present and fix config argument ([PR#4017](https://github.com/OHIF/Viewers/pull/4017))
* fix : Keycloak docker image source modification ([PR#4015](https://github.com/OHIF/Viewers/pull/4015))
* feat(advanced-roi-tools): new tools and icon updates and overlay bug fixes ([PR#4014](https://github.com/OHIF/Viewers/pull/4014))
* fix(presentation-state): Iterate over map properly to restore the presentation state ([PR#4013](https://github.com/OHIF/Viewers/pull/4013))
* fix(new layout): address black screen bugs ([PR#4008](https://github.com/OHIF/Viewers/pull/4008))
* fix(MetaDataProvider): Fix tag in GeneralImageModule ([PR#4000](https://github.com/OHIF/Viewers/pull/4000))
* feat(worklist): new investigational use text ([PR#3999](https://github.com/OHIF/Viewers/pull/3999))
* fix(cornerstone-dicom-sr): Freehand SR hydration support ([PR#3996](https://github.com/OHIF/Viewers/pull/3996))
* fix(SR display): and the token based navigation ([PR#3995](https://github.com/OHIF/Viewers/pull/3995))
* feat(worklist): New worklist buttons and tooltips ([PR#3989](https://github.com/OHIF/Viewers/pull/3989))
* feat(segmentation): Enhanced segmentation panel design for TMTV  ([PR#3988](https://github.com/OHIF/Viewers/pull/3988))
* fix(viewport-sync): Enable re-sync image slices in a different position when needed ([PR#3984](https://github.com/OHIF/Viewers/pull/3984))
* feat(toolbar): new Toolbar to enable reactive state synchronization  ([PR#3983](https://github.com/OHIF/Viewers/pull/3983))
* fix(cli): mode creation template (#3876) ([PR#3981](https://github.com/OHIF/Viewers/pull/3981))
* docs: Typo ([PR#3975](https://github.com/OHIF/Viewers/pull/3975))
* feat(ViewportActionMenu): window level per viewport / new patient info / colorbars/ 3D presets and 3D volume rendering ([PR#3963](https://github.com/OHIF/Viewers/pull/3963))
* fix(docs): Minor typos in hpModule.md ([PR#3962](https://github.com/OHIF/Viewers/pull/3962))
* fix(demo): Deploy issue ([PR#3951](https://github.com/OHIF/Viewers/pull/3951))
* feat(errorboundary): format stack trace properly ([PR#3931](https://github.com/OHIF/Viewers/pull/3931))
* feat(layout): new layout selector ([PR#3923](https://github.com/OHIF/Viewers/pull/3923))
* docker: Enabled Orthanc's DIMSE port for easier loading of DICOM studies in OpenResty-Orthanc Recipe ([PR#3917](https://github.com/OHIF/Viewers/pull/3917))
* fix: is same orientation ([PR#3905](https://github.com/OHIF/Viewers/pull/3905))
* fix: 🐛 Sort merge results based on default data source (input) ([PR#3903](https://github.com/OHIF/Viewers/pull/3903))
* fix: 🐛 Check merge key for merge data source ([PR#3901](https://github.com/OHIF/Viewers/pull/3901))
* fix toggleHpTools to support split primary button ([PR#3900](https://github.com/OHIF/Viewers/pull/3900))
* fix: catch errors in getPTImageIdInstanceMetadata ([PR#3897](https://github.com/OHIF/Viewers/pull/3897))
* fix: Microscopy bulkdata and image retrieve ([PR#3894](https://github.com/OHIF/Viewers/pull/3894))
* fix: Update CS3D to fix second render ([PR#3892](https://github.com/OHIF/Viewers/pull/3892))
* docs: Update architecture.md ([PR#3891](https://github.com/OHIF/Viewers/pull/3891))
* feat(resize): Optimize resizing process and maintain zoom level ([PR#3889](https://github.com/OHIF/Viewers/pull/3889))
* fix(segmentation): upgrade cs3d to fix various segmentation bugs ([PR#3885](https://github.com/OHIF/Viewers/pull/3885))
* feat(transferSyntax): prefer server transcoded transfer syntax for all images ([PR#3883](https://github.com/OHIF/Viewers/pull/3883))
* feat: Add on mode init hook ([PR#3882](https://github.com/OHIF/Viewers/pull/3882))
* fix: convert radian to degree value for mip rotation ([PR#3881](https://github.com/OHIF/Viewers/pull/3881))
* fix: PDF display request in v3 ([PR#3878](https://github.com/OHIF/Viewers/pull/3878))
* docs: Update user-account-control.md ([PR#3877](https://github.com/OHIF/Viewers/pull/3877))
* feat: improve disableEditing flag ([PR#3875](https://github.com/OHIF/Viewers/pull/3875))
* fix: colormap for stack viewports via HangingProtocol ([PR#3866](https://github.com/OHIF/Viewers/pull/3866))
* feat(measurement): Add support measurement label autocompletion ([PR#3855](https://github.com/OHIF/Viewers/pull/3855))
* feat(overlay): add inline binary overlays ([PR#3852](https://github.com/OHIF/Viewers/pull/3852))
* fix: address and improve system vulnerabilities ([PR#3851](https://github.com/OHIF/Viewers/pull/3851))
* fix(viewport-sync): remember synced viewports bw stack and volume and RENAME StackImageSync to ImageSliceSync ([PR#3849](https://github.com/OHIF/Viewers/pull/3849))
* feat(config): Add activateViewportBeforeInteraction parameter for viewport interaction customization ([PR#3847](https://github.com/OHIF/Viewers/pull/3847))
* feat(HP): Added new 3D hanging protocols to be used in the new layout selector ([PR#3844](https://github.com/OHIF/Viewers/pull/3844))
* feat(customizationService): Enable saving and loading of private tags in SRs  ([PR#3842](https://github.com/OHIF/Viewers/pull/3842))
* fix(auth): fix the issue with oauth at a non root path ([PR#3840](https://github.com/OHIF/Viewers/pull/3840))
* fix(dicom-seg) : Fix the missing color tag when loading Dicom-Seg ([PR#3822](https://github.com/OHIF/Viewers/pull/3822))
* fix(SM): drag and drop is now fixed for SM ([PR#3813](https://github.com/OHIF/Viewers/pull/3813))
* fix(cine): Set cine disabled on mode exit. ([PR#3812](https://github.com/OHIF/Viewers/pull/3812))
* Fix(HPService): custom image load performed is now reset to false on HP exit ([PR#3809](https://github.com/OHIF/Viewers/pull/3809))
* fix: Update the CS3D packages to add the most recent HTJ2K TSUIDS ([PR#3806](https://github.com/OHIF/Viewers/pull/3806))
* feat(hp): enable OHIF to run with partial metadata for large studies at the cost of less effective hanging protocol ([PR#3804](https://github.com/OHIF/Viewers/pull/3804))
* feat(events): broadcast series summary metadata ([PR#3798](https://github.com/OHIF/Viewers/pull/3798))
* feat(docs): Added various training videos to support the OHIF CLI tools ([PR#3794](https://github.com/OHIF/Viewers/pull/3794))
* fix(DICOM Overlay): The overlay data wasn't being refreshed on change ([PR#3793](https://github.com/OHIF/Viewers/pull/3793))
* Merge Data Source ([PR#3788](https://github.com/OHIF/Viewers/pull/3788))
* fix(metadata): to handle cornerstone3D update for htj2k ([PR#3783](https://github.com/OHIF/Viewers/pull/3783))
* fix(overlay): Overlays aren't shown on undefined origin ([PR#3781](https://github.com/OHIF/Viewers/pull/3781))
* fix(path): upgrade docusaurus for security ([PR#3780](https://github.com/OHIF/Viewers/pull/3780))
* feat(dicomJSON): Add Loading Other Display Sets and JSON Metadata Generation script ([PR#3777](https://github.com/OHIF/Viewers/pull/3777))
* feat: add VolumeViewport rotation ([PR#3776](https://github.com/OHIF/Viewers/pull/3776))
* feat(delete measurement): icon for measurement table ([PR#3775](https://github.com/OHIF/Viewers/pull/3775))
* fix: 🐛 Run error handler for failed image requests ([PR#3773](https://github.com/OHIF/Viewers/pull/3773))
* feat(hp callback): Add viewport ready callback ([PR#3772](https://github.com/OHIF/Viewers/pull/3772))
* fix(arrow): ArrowAnnotate text key cause validation error ([PR#3771](https://github.com/OHIF/Viewers/pull/3771))
* docs: Update HangingProtocolService.md doc sameAs example to contain constr… ([PR#3765](https://github.com/OHIF/Viewers/pull/3765))
* docs(faq): FAQ and Segmentation Mode Fix for some studies ([PR#3762](https://github.com/OHIF/Viewers/pull/3762))
* feat(i18n): enhanced i18n support ([PR#3761](https://github.com/OHIF/Viewers/pull/3761))
* fix(thumbnail): Avoid multiple promise creations for thumbnails ([PR#3756](https://github.com/OHIF/Viewers/pull/3756))
* fix(measurement service): Implemented correct check of schema keys in _isValidMeasurment. ([PR#3750](https://github.com/OHIF/Viewers/pull/3750))
* feat(filters): save worklist query filters to session storage so that they persist between navigation to the viewer and back ([PR#3749](https://github.com/OHIF/Viewers/pull/3749))
* feat(url): Add SeriesInstanceUIDs wado query param ([PR#3746](https://github.com/OHIF/Viewers/pull/3746))
* fix(recipes): package.json script orthanc:up docker-compose path ([PR#3741](https://github.com/OHIF/Viewers/pull/3741))
* docs(azure): Azure static Deployment details ([PR#3740](https://github.com/OHIF/Viewers/pull/3740))
* fix(sr): dcm4chee requires the patient name for an SR to match what is in the original study ([PR#3739](https://github.com/OHIF/Viewers/pull/3739))
* fix(calibration): No calibration popup caused by perhaps an unused code optimization for production builds ([PR#3736](https://github.com/OHIF/Viewers/pull/3736))
* fix(cine): Use the frame rate specified in DICOM and optionally auto play cine ([PR#3735](https://github.com/OHIF/Viewers/pull/3735))
* feat(i18n): enhanced i18n support ([PR#3730](https://github.com/OHIF/Viewers/pull/3730))
* feat(ui): sidePanel expandedWidth ([PR#3728](https://github.com/OHIF/Viewers/pull/3728))
* fix(icon-style): Ensure consistent icon dimensions ([PR#3727](https://github.com/OHIF/Viewers/pull/3727))
`;

function ReleaseNotes() {
  return (
    <Page>
      <SEO title="OHIF v3.8 | OHIF" />
      <Section className="mx-auto">
        <Title title="OHIF Viewer v3.8" secondary="April 29, 2024" />

        <div className="mt-10">
          <Header />
        </div>
        <div className="flex flex-col space-y-32 mt-12 ">
          <div>
            <div id="overview">
              <SubTitle title={"Overview Video"} />
              <VideoView src="https://player.vimeo.com/video/941614408" />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div id="new-features">
            <SubTitle title={"New Features"} />
            <div className="space-y-32">
              <Feature
                position="left"
                title="New Enhanced Layout Menu"
                videoSrc="https://player.vimeo.com/video/940832559"
              >
                <p>
                  Customize your viewing experience with the powerful new
                  enhanced layout selector tool. Easily switch between
                  preconfigured advanced layouts like "MPR", "3D four up", and
                  more. With just a click, seamlessly transition between
                  standard grids and advanced 3D layouts or introduce your own
                  custom views into the toolbar for quick access. This flexible
                  new tool streamlines your workflow and provides an environment
                  perfectly suited for efficient imaging analysis.
                </p>
              </Feature>

              <Feature
                position="right"
                title="Streamlined Visualization with Per-Viewport Controls"
                videoSrc="https://player.vimeo.com/video/940832625"
              >
                <p>
                  Elevate your image analysis with our enhanced per-viewport
                  rendering controls in OHIF Viewer v3.8.
                </p>
                <p>
                  Get instant access to relevant window level presets tailored
                  to each viewport's data type - whether CT, MRI, 3D Rendering,
                  or more. This intuitive design simplifies your workflow,
                  especially when working with multi-modality fusion viewports.
                </p>
                <p>
                  Plus, refine your reading experience with our new color lookup
                  table selector, featuring in-viewport previews and a clear
                  color bar to visualize value ranges. Take control of your
                  imaging data like never before.
                </p>
              </Feature>

              <Feature
                position="left"
                title="Simplify Complex Analysis with Workflow Steps"
                imageSrcs={[workflowSteps]}
              >
                <p>
                  Break down intricate tasks into clear, manageable stages with
                  OHIF Viewer v3.8's innovative Workflow Steps. Tailor the
                  interface to display only the essential tools and view
                  settings, and user interface needed for each step, and
                  experience a more focused and guided workflow.
                </p>
              </Feature>

              <Feature
                position="right"
                title="4D Visualization"
                videoSrc="https://player.vimeo.com/video/940832655"
              >
                <p>
                  Experience comprehensive 4D support across the OHIF Viewer
                  with our newly improved CINE player for smooth navigation of
                  dynamic imaging data.
                </p>
                <p>
                  This feature is especially beneficial for viewing time-series
                  data, where you can easily adjust the frame rate, play
                  direction, and loop mode to suit your needs, all while
                  maintaining a high level of performance.
                </p>
                <p>
                  We've introduced a new 4D PET/CT mode, leveraging the latest
                  Workflow Steps. This innovative mode combines 3D CT and 4D PET
                  data in a 9-viewport layout, facilitating advanced
                  visualization and analysis. Users can now seamlessly browse 4D
                  images, jump to specific frames, and create computed images
                  for analysis, such as sum, subtraction, or averaging frames,
                  all within a streamlined workflow.
                </p>
                <p>
                  This project was made possible through the generous funding
                  support of Washington University in St. Louis and Dr. Kooresh
                  Shoghi.
                </p>
              </Feature>

              <Feature
                position="left"
                title="Unlock Deeper Insights from Your Ultrasound Data"
                imageSrcs={[ultrasound]}
              >
                <p>
                  Get more from your ultrasound data with enhanced region of
                  interest (ROI) metadata support. Our annotation tools now
                  utilize this valuable information to provide accurate analysis
                  and spacing insights. Make the most of your ultrasound data,
                  effortlessly.
                </p>
              </Feature>

              <Feature
                position="right"
                title="Advanced Tools"
                videoSrc="https://player.vimeo.com/video/940832514"
              >
                <p>
                  Enhance your image analysis with our new set of advanced tools
                  in OHIF viewer's segmentation tools. Experience unparalleled
                  precision with:
                </p>
                <ul>
                  <li>
                    Spline tools featuring various basis function supports
                  </li>
                  <li>Freehand ROI annotation tools for flexible marking</li>
                  <li>
                    Livewire, with magnetic snapping to edges for effortless
                    tracing
                  </li>
                  <li>
                    Dynamic threshold tool, adapting the threshold to mouse
                    position for optimal thresholding
                  </li>
                  <li>
                    Advanced magnify tool, a movable probe offering variable
                    zoom levels and real-time annotation visibility
                  </li>
                </ul>
                <p>
                  Streamline your workflow and unlock new insights with these
                  cutting-edge tools.
                </p>
              </Feature>

              <Feature
                position="left"
                title="Efficient Labeling with Auto-Complete Suggestions"
                imageSrcs={[autocomplete]}
              >
                <p>
                  Streamline measurement labeling with our intuitive
                  auto-complete feature, contributed by Flywheel with support
                  from Gates Ventures. Predefine labels and let OHIF prompt
                  users to select from suggested options. As users type, the
                  system filters the list, making it easy to find the right
                  label and save valuable time.
                </p>
              </Feature>

              <Feature
                position="right"
                title="DICOM Conformance Statement"
                imageSrcs={[dicom]}
              >
                <p>
                  Get access to our new DICOM Conformance Statement, now
                  available for download. This comprehensive guide provides a
                  detailed breakdown of OHIF Viewer's DICOM capabilities,
                  including supported services, Information Object Definitions
                  (IODs), and transfer syntaxes. Special thanks to Radical
                  Imaging for making this valuable resource available to the
                  public.
                </p>
              </Feature>

              <Feature
                position="left"
                title="Other Viewer Updates"
                imageSrcs={[toolbar]}
              >
                <h4>Optimizing resizing of the viewports</h4>
                <p>
                  Enjoy a smoother experience with our improved viewport
                  resizing. We have eliminated the lag that occurred when
                  collapsing UI panels, making it faster and more pleasant to
                  resize images within viewports.
                </p>

                <h4>Introducing the Revamped Toolbar</h4>
                <p>
                  Experience a completely revamped toolbar, redesigned from the
                  ground up. Our new architecture simplifies the process of
                  adding buttons and tools, eliminating the need for hacks and
                  workarounds.
                </p>
                <p>
                  The toolbar is now reactive to the active viewport, disabling
                  irrelevant tools until the corresponding viewport is selected.
                  Additionally, our new Toolbox component allows you to place
                  custom buttons anywhere, from panels to pop-up modals. Learn
                  more in our comprehensive migration guides and explore the
                  full potential of our revamped toolbar.
                </p>
              </Feature>
            </div>
          </div>
          <div id="all-changes"></div>
        </div>
        <div>
          <SubTitle title={"All Changes"} />

          <div className="text-white">
            <MarkdownRenderer text={ChangeLog} />
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
